import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {

  private loadingCount = 0;
  private _loaderStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _columnLoaderStatus$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  private _counter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  show(): void {
    if (this.loadingCount < 1) {
      this._loaderStatus$.next(true);
    }

    this.loadingCount += 1;
  }

  hide(): void {
    if (this.loadingCount > 0) {
      this.loadingCount -= 1;
    }

    if (this.loadingCount < 1) {
      this._loaderStatus$.next(false);
    }
  }

  get loader(): Observable<boolean> {
    return this._loaderStatus$.asObservable();
  }

  get columnLoader$(): Observable<number[]> {
    return this._columnLoaderStatus$.asObservable();
  }

  get counter(): Observable<boolean> {
    return this._counter$.asObservable();
  }

  showColumnLoader(statusIds: number[]): void {
    this._columnLoaderStatus$.next(statusIds);
  }

  hideColumnLoader(): void {
    this._columnLoaderStatus$.next([]);
  }

  showCounter(): void {
    this._counter$.next(true);
  }

  hideCounter(): void {
    this._counter$.next(false);
  }
}
